import React, { memo, useCallback, useState } from "react";
import Paginator from "../../../ui/pagination";
import { NavLink } from "react-router-dom";
import { useDebouncedValue } from "@mantine/hooks";
import { IPaginatedRequest } from "../../../../interfaces";
import { useQuery } from "react-query";
import queryString from "query-string";
import { downloadBillingRerpot, getPaginatedPayment } from "../../../../actions/billings";
import { convertPrice } from "../../tables/gestao/UpdateGestao";
import { DateTime } from "luxon";
import BillingPayoutsNav from "../../../ui/billing-payouts-nav/BillingPayoutsNav";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import { toast } from "react-toastify";
import { useIsAdmin } from "../../../../hooks/useUser";
import SellerInvoiceUploadModal from "./SellerInvoiceUploadModal";

const PaymentBatchTable = () => {
  const admin = useIsAdmin();
	const [search, setSearch] = useState<string>("");
  const [invoiceModal, setInvoiceModal] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState(0);

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		search: "",
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);

	const { isLoading: loadPayments, data: payments } = useQuery(
		// `/billings/paginatedShipments?${queryString.stringify(debouncedConfig)}`,
		`/payouts/payment?${queryString.stringify(debouncedConfig)}`,
		() => getPaginatedPayment(debouncedConfig)
	);

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c: IPaginatedRequest) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

  const handleDownloadReport = async (id: number, name: string) => {
		try {
			await downloadBillingRerpot(id, name);
		} catch (error: any) {
			toast.error(error.message)
		}
	};

  const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
    setInvoiceModal(false);
	};

	return (
		<div className="main-body">
			<div className="main-container">
				<div className="table-main-cont">
					<BillingPayoutsNav />
					<div className="table-main-title-cont">
						<div className="table-main-title-search-value-cont">
							<div className="table-search-box">
								<form
									className="table-search-form ml2"
									onSubmit={(e) => {
										e.preventDefault();
										const form = e.target as HTMLFormElement;
										setCurrentConfig({
											...currentConfig,
											search: form["search"].value,
										});
									}}
								>
									<input
										className="table-search-input"
										name="search"
										placeholder="Search product"
										value={search}
										onChange={(e) => setSearch(e.target.value)}
									/>
									<img
										className="table-search-image"
										src="/icons/search.svg"
										width="25px;"
										alt=""
									/>
								</form>
							</div>
							<div>
								{loadPayments ? (
									<CircularPreloader />
								) : (
									<span className="table-main-title-text">
										{payments?.data.length} payment batches
									</span>
								)}
							</div>
						</div>
						<div className="marketplace-pagination">
							<Paginator
								data={!loadPayments ? payments : {}}
								refetch={onChangePage as any}
							/>
						</div>
					</div>
					<div className="table-border-bottom"></div>
					<div style={{ height: "max-content" }} className="table-overflow">
						<table
							style={{
								position: "sticky",
								zIndex: 101,
								top: "2px",
								width: "100%",
							}}
						>
							<thead>
								<tr
									style={{ marginTop: "-5px", width: "100%" }}
									className="table-results-title"
								>
									<th className="paybatch-id">Batch ID</th>
									<th className="paybatch-desc">Description</th>
									<th className="paybatch-amount-bil">
										Billings
									</th>
									<th className="paybatch-products">Products</th>
									<th className="paybatch-amount">
                    Amount<br/> w/VAT
                  </th>
									<th className="paybatch-date">Date / Time</th>
                  <th className="paybatch-amount-bil">Invoice</th>
									<th className="paybatch-status">Status</th>
								</tr>
							</thead>
							<tbody></tbody>
						</table>
						<div>
							<table className="table-results p0">
								<thead></thead>
								<tbody>
									{payments?.data.map((item: any, index: number) => (
										<tr className={`table-result-box billing-text ${item.type}`} key={index}>
											<td className="paybatch-id bold">
                        <NavLink to={`/payouts/payment/${item.payment_id}`}>
                          {item.payment_id}
                        </NavLink>
											</td>
											<td className="paybatch-desc">
                        <NavLink to={`/payouts/payment/${item.payment_id}`}>
                          {item.name}
                        </NavLink>
                      </td>
											<td className="paybatch-amount-bil">
												{/* <NavLink to="/create-paymentbatch" className="in-row align-center"> */}
												<img
													className="mr1 pointer"
													src="/icons/paybatch-amountbil.svg"
													title="Download billing report"
                          alt=""
                          onClick={async() => handleDownloadReport(item.payment_id, item.name)}
												/>
												{item.billings.length}
												{/* </NavLink> */}
											</td>
											<td className="paybatch-products">
												<img
													className="mr1"
													src="/icons/paybatch-product.svg"
													alt=""
												/>
												{item.products}
											</td>
											<td className="paybatch-amount bold">
												{convertPrice(item.amount)} €
											</td>
											<td className="paybatch-date">
												{DateTime.fromISO(item.date || item.created).toLocaleString(
													DateTime.DATETIME_SHORT
												)}
											</td>
                      <td className="paybatch-amount-bil">
                       {
                        item.type === "outbound" 
                          ? 
                          <>
                            <img
                              className="mr1 filter-grey pointer"
                              src="/icons/import-products.svg"
                              alt=""
                              title="Upload invoce"
                              onClick={() => {
                                setSelectedPayment(item.payment_id)
                                setInvoiceModal(true);
                              }}
                            />
                            {item.invoice?.url &&
                              <a href={item.invoice.url} target="_blank" className="in-row align-center">
                                <img
                                  className="mr1 pointer"
                                  src="/icons/green-check.svg"
                                  alt=""
                                  title="Download invoce" />
                              </a>
                            }
                          </>                                                                                                          
                          :
                          <>
                            {item.invoice?.url &&
                              <a href={item.invoice.url} target="_blank" className="in-row align-center">
                                <img
                                  className="mr1  pointer"
                                  src="/icons/download-file.svg"
                                  alt=""
                                  title="Download invoce"
                                />
                              </a>
                            }
                          </>
                       }
											</td>
											<td className="paybatch-status">
												<div className={`paybatch-${item.status}`}>
													{item.status}
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
      {invoiceModal && 
        <SellerInvoiceUploadModal
          payment_id={selectedPayment}
          onModalClose={onModalClose}
        />
      }
		</div>
	);
};

export default memo(PaymentBatchTable);
