import React, { useEffect, useState } from "react";
import { Link, RouteComponentProps, useHistory, withRouter } from 'react-router-dom';
import { Paginator } from "../../../ui/pagination/Paginator";
import { useQuery } from "react-query";
import { editPayment, getPayment } from "../../../../actions/billings";
import Preloader from "../../../ui/preloader/Preloader";
import { convertPrice } from "../../tables/gestao/UpdateGestao";
import { orderBy, uniq } from "lodash";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import { DateTime } from "luxon";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { useIsAdmin } from "../../../../hooks/useUser";
import SellerInvoiceUploadModal from "./SellerInvoiceUploadModal";

type TParams = {
	id: string;
};

// const SingleOrder: React.FC<RouteComponentProps<TParams>> = (props) => {

const EditPayment: React.FC<RouteComponentProps<TParams>> = (props) => {
  const payment_id = props.match.params.id;
  // eslint-disable-next-line
  const [paginatedData, setPaginatedData] = useState<number>();
  const [limit, setLimit] = useState<number>(50);
  const [auxPayment, setAuxPayment] = useState<any>({});
  const [deletedBillings, setDeletedBillings] = useState<string[]>([]);
  const [creditNoteModal, setCreditNoteModal] = React.useState(false);
  const admin = useIsAdmin();
  
  const history = useHistory();

  const { isLoading: loadPayment, data: payment } = useQuery(
    `/payouts/payment/${payment_id}`,
    () => getPayment(payment_id)
  );

  const { isLoading, data } = useActiveVendors();

  const getSeller = (seller: number) => {
    return data.find((s: any) => s.id === seller);
  }

  const sellerBillings = (seller: number) => {
    return orderBy(auxPayment?.billings?.filter((b: any) => b.partner_id === seller), "related_sale_order");
  }

  const totalPayment = (billings: any) => {
    let total = 0;
    billings.map((bl: any) => 
      total += bl.billing_lines.reduce((total: number, curr: any) => total + curr.price_total, 0)
    );
    return total;
  }

  const totalProducts = (lines: any) => {
    return lines.reduce((total: number, curr: any) => total + curr.product_uom_qty, 0);
  }

  const totalBilling = (lines: any) => {
    return lines.reduce((total: number, curr: any) => total + curr.price_total, 0);
  }

  const zeoosName = (billing: any) => {
    return auxPayment.order.find(
      (o: any) => o.order_id === billing.related_sale_order
    )?.zeoosName
  }

  const [status, setStatus] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [transaction, setTransaction] = useState<string | undefined>(undefined);
  const [paymentDate, setPaymentDate] = useState<Date | undefined>(undefined);
  let initStatus:string, initName:string, initBillings:any[];

  useEffect(() => {
    setStatus(payment?.status);
    setName(payment?.name);
    setAuxPayment(payment);
    setTransaction(payment?.transaction);
    if (payment?.paymentDate) setPaymentDate(new Date(Date.parse(payment.paymentDate)));
	}, [payment]);

  if (loadPayment || isLoading) {
    return <Preloader />;
  }

  const sellers = uniq(auxPayment?.billings?.map((b: any) => b.partner_id));

  const totalCredit = totalPayment(sellerBillings(payment.seller.id)?.filter((b:any) => b.credit_note));

  const handleEditPayment = async (e:any) => {
    e.target.disabled = true;
    e.target.className=`${e.target.className} disabled`;
    let error, message;

    if ((!status && !name) ||
        (initStatus === status && initName === name 
          && auxPayment.billings.length === initBillings.length)
      ) {
      error = "No changes made";
    } 

    if (!auxPayment.billings.length) {
      error = "No billings selected";
    }

    if (status === 'paid' &&
      auxPayment.billings.find((b: any) => b.payment_state === 'pending')) {
      error = "Some billings are still PENDING";
    }

    switch (payment.type) {
      case "inbound":
        if (status === 'paid' && !paymentDate) {
          error = "You must fill DATE";
        }

        break;

      case "outbound":      
        if (status === 'paid') {
          if (!transaction || !paymentDate) {
            error = "You must fill TRANSACTION and DATE";
          }

          if (payment.billings.find((b: any) => b.credit_note) && !payment?.creditNote?.url) {
            error = "Seller must upload CREDIT NOTE first";
          }

          if (!payment?.invoice?.url) {
            error = "Seller must upload INVOICE first";
          }
        }
        break;
    
      default:
        break;
    }

    if (initStatus === 'paid') {
      error = "Batch is already PAID";
    }

    if (!error) {
      const edit = await editPayment({
        payment_id: auxPayment.payment_id,
        status,
        name,
        billings: auxPayment.billings.map((b: any) => b._id),
        deletedBillings,
        paymentDate,
        transaction
      });

      if (edit.success) {
        message = edit.message;
      } else {
        error = edit.message;
      }
    }

    if (error) {
      toast.error(error, { autoClose: false });
    } else {
      toast.success(message);
      history.push("/payouts/batches");
    }
    
    e.target.disabled = false;
    e.target.className = e.target.className.replace(' disabled','');
  }

  // eslint-disable-next-line
  const removeBilling = (id: string) => {
    setAuxPayment({
      ...auxPayment,
      billings: auxPayment.billings.filter((b: any) => b._id !== id)
    });

    setDeletedBillings([...deletedBillings, id]);
  }

  if (payment) {
    initStatus = payment.status;
    initName = payment.name;
    initBillings = payment.billings;
  }

  const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
    setCreditNoteModal(false);
	};
  
  return (
      <>
          <div style={{ marginTop: "-60px", zIndex: "1200" }} className="product-header-cont">
              <div className="in-row align-cener height100">
                  <Link to={'../batches'} className="product-exit-btn">
                      <img className="mr2" src="/icons/product-exit.svg" alt="" />
                      Discard
                  </Link>
                  <div className="in-row align-center ml2">
                      <span className="product-header-text ml1">Payment Batch ID: {payment.payment_id}</span>
                  </div>
              </div>
              <button
                onClick={handleEditPayment}
                disabled={!admin || payment?.status === "paid" ? true : false}
                className={`product-header-button ${!admin || payment?.status === "paid" ? "disabled" : ""}`}>
                  SAVE
              </button>
          </div>
          <div style={{ background: "#E8EDF2", height: "max-content", minHeight: "80vh" }} className="product-cont m0 pt4">
              <div className="spayment-column">
                  <div className="spayment-cont">
                      <div className="in-row align-center justify-between width100 mb4">
                          <span className="spayment-title-text">Payment Batch information</span>
                      </div>
                      <div className="spayment-box">
                          <span className="spayment-text mr4">Description:</span>
                          <input style={{ background: "#f2f2f2"}} 
                            placeholder="Payment Batch description text" 
                            className="tax-modal-input" 
                            value={name}
                            disabled={!admin}
                            onChange={(e) => setName(e.target.value)}
                          />
                      </div>
                      {status === "paid" &&
                        <div className="spayment-box paid-action">
                          <span className="spayment-text mr4">Transaction:</span>
                          <input style={{ background: "#f2f2f2", marginRight: 20, width: "70%"}} 
                            placeholder="Transaction number" 
                            className="tax-modal-input" 
                            disabled={!admin}
                            value={transaction}
                            onChange={(e) => setTransaction(e.target.value)}
                          />
                          <span className="spayment-text mr4">Date:</span>
                          <div className="mr4">
                            <DatePicker
                              className="tax-modal-input"
                              selected={paymentDate}
                              dateFormat="dd/MM/yyyy"
                              disabled={!admin}
                              onChange={(date: any) => {
                                setPaymentDate(date);
                              }}
                              // customInput={<ExampleCustomInput />}
                            />
                          </div>
                          <span className="spayment-text mr4">Invoice:</span>
                          {payment?.invoice?.url 
                            ? <img
                                className="mr1"
                                src="/icons/green-check.svg"
                                alt=""
                                title="Invoce uploaded" />
                            : <img
                            className="mr1"
                            src="/icons/grey-cancel-circle.svg"
                            alt=""
                            title="Missing Invoce" />
                          }
                        </div>
                      }
                      <div className="spayment-box justify-between">
                          <div className="spayment-text">
                            <div>
                              Total amount to be paid:  <b className="ml4">{convertPrice(payment.amount)} €</b>
                            </div>
                              
                            {totalCredit ?
                              <>
                                <div className="justify-between">
                                  Credit notes:
                                  <b className="ml4 red">{convertPrice(totalCredit)} €</b>
                                </div>
                                <div className="justify-between">
                                  <b>Total to transfer:</b>
                                  <b className="ml4">{convertPrice(payment.amount - totalCredit)} €</b>
                                </div>
                              </>                              
                              : ''                           
                            }                            
                          
                          </div>
                          <div className="in-row align-center">
                              <div className="spayment-text mr4">Status:</div>
                                  <div className={`paybatch-processing mr2 pointer ${status === 'paid'?'inactive':''}`}
                                    onClick={() => {
                                      if (admin && initStatus === 'processing') setStatus('processing');
                                    }}
                                    >
                                      Processing
                                  </div>
                                  <div className={`spaybatch-paid pointer ${status === 'paid'?'active':''}`}
                                    onClick={() => {
                                      if (admin) setStatus('paid')
                                    }}
                                    >
                                      Paid
                                  </div>
                          </div>
                      </div>
              </div>
              <div className="table-main-title-cont mt4">
                  <div className="table-main-title-search-value-cont">
                      <div style={{textTransform: "uppercase"}} className="spayment-title-text bold black ml4">
                        {payment.billings.length} Open Billings - {sellers.length} sellers
                      </div>
                  </div>
                  <div className="marketplace-pagination">
                      <Paginator
                          data={{}}
                          rowsPerPage={limit}
                          setPaginatedData={setPaginatedData}
                          setLimit={setLimit}
                          limit={limit}
                      />
                  </div>
              </div>
              <div className="table-border-bottom"></div>
                  <div style={{ height: "max-content" }} className="table-overflow">
                      <table style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}>
                          <thead>
                              <tr
                                  style={{ marginTop: "-5px", width: "100%" }}
                                  className="table-results-title"
                              >
                                      <th className="spaybatch-id ml4">Billing ID</th>
                                      <th className="spaybatch-order">ID Order</th>
                                      <th className="spaybatch-name">Plaform Name</th>
                                      <th className="spaybatch-products">Products</th>
                                      <th className="spaybatch-amount">Amount</th>
                                      <th className="spaybatch-date">Date / Time</th>
                              </tr>
                          </thead>
                          <tbody></tbody>
                      </table>
                      <div>
                          <table className="table-results p0">
                              <thead></thead>
                              <tbody>
                                {sellers.map((seller_id: any, index: number) => {
                                  const seller = getSeller(seller_id);
                                  const billingsSeller = sellerBillings(seller.id).filter((b:any) => !b.credit_note);
                                  const creditNotes = sellerBillings(seller.id).filter((b:any) => b.credit_note);      
                                  
                                  return (
                                    <React.Fragment key={index}>
                                      <tr style={{background: "#F2F2F2", height: "40px"}} className="table-result-box billing-text">
                                        <td className="in-row align-center justify-between width100 ml4 mr4">
                                            <div>
                                                <b className="mr1">{seller.displayName||seller.name}</b>
                                                  - {billingsSeller.length} billings ({convertPrice(totalPayment(billingsSeller))} €)
                                            </div>
                                        </td>
                                      </tr>
                                      {billingsSeller.map((billing: any, index: number) => (
                                        <tr className="table-result-box billing-text" key={index}>
                                          <td className="spaybatch-id pl3">
                                            {billing.payment_state === 'pending' && 
                                              <img className="mr2 pointer spaybatch-remove" src="/icons/spaybatch-close.svg" 
                                                alt="" title="Pending Billing"
                                                // onClick={() => removeBilling(billing._id)}
                                              />
                                            }
                                            {billing.billing_name}
                                          </td>
                                          <td className="spaybatch-order">
                                            {billing.related_sale_order}
                                          </td>
                                          <td className="spaybatch-name">
                                            {zeoosName(billing)}
                                          </td>
                                          <td className="spaybatch-products">
                                            <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                                            {totalProducts(billing.billing_lines)}
                                          </td>
                                          <td className="spaybatch-amount bold">
                                            {billing.billing_transaction_type === 'order_refund' ? '-' : ''}
                                            {convertPrice(totalBilling(billing.billing_lines))} €
                                          </td>
                                          <td className="spaybatch-date">
                                            {DateTime.fromISO(billing.date_creation).toLocaleString(DateTime.DATETIME_SHORT)}
                                          </td>
                                        </tr>
                                      ))}

                                      {creditNotes.length > 0 
                                        ? 
                                        <tr style={{background: "#F2F2F2", height: "40px"}} className="table-result-box billing-text">
                                          <td className="in-row align-center justify-between width100 ml4 mr4">
                                              <div>
                                                  <b className="mr1">Credit notes</b>
                                                    - {creditNotes.length} billings (-{convertPrice(totalPayment(creditNotes))} €)
                                              </div>
                                              
                                              <div className="in-row">
                                                <img 
                                                  className="mr1 filter-grey pointer" 
                                                  src="/icons/import-products.svg" 
                                                  alt="" 
                                                  title="Upload credit note" 
                                                  onClick={() => setCreditNoteModal(true)}
                                                />
                                                {auxPayment.creditNote?.url &&
                                                  <a href={auxPayment.creditNote.url} target="_blank" rel="noreferrer" className="in-row align-center">
                                                    <img
                                                      className="mr1 pointer"
                                                      src="/icons/green-check.svg"
                                                      alt=""
                                                      title="Download credit note" />
                                                  </a>
                                                }
                                              </div>
                                          </td>
                                        </tr>
                                        : ""
                                      }
                                      {creditNotes.map((billing:any, index: number) => 
                                        <tr className="table-result-box billing-text" key={index}>
                                          <td className="spaybatch-id pl3">
                                            {billing.payment_state === 'pending' && 
                                              <img className="mr2 pointer spaybatch-remove" src="/icons/spaybatch-close.svg" 
                                                alt="" title="Pending Billing"
                                                // onClick={() => removeBilling(billing._id)}
                                              />
                                            }
                                            {billing.billing_name}
                                          </td>
                                          <td className="spaybatch-order">
                                            {billing.related_sale_order}
                                          </td>
                                          <td className="spaybatch-name">
                                            {zeoosName(billing)}
                                          </td>
                                          <td className="spaybatch-products">
                                            <img className="mr1" src="/icons/paybatch-product.svg" alt="" />
                                            {totalProducts(billing.billing_lines)}
                                          </td>
                                          <td className="spaybatch-amount bold">
                                            {/* {billing.billing_transaction_type === 'order_refund' ? '-' : ''} */}
                                            - {convertPrice(totalBilling(billing.billing_lines))} €
                                          </td>
                                          <td className="spaybatch-date">
                                            {DateTime.fromISO(billing.date_creation).toLocaleString(DateTime.DATETIME_SHORT)}
                                          </td>
                                        </tr>
                                      )}
                                    </React.Fragment>                                    
                                  )
                                })}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
              {creditNoteModal && 
                <SellerInvoiceUploadModal
                  payment_id={auxPayment.payment_id}
                  onModalClose={onModalClose}
                  credit_note={true}
                />
              }
          </div>
      </>
  );
}

export default withRouter(EditPayment);
